import React from 'react'
import {ContentWithShare} from 'components/ContentWithShare'
import TwitterTimeLine from 'components/twitterTimeLine'
import {Header} from './parts/header'
import Styles from './subjectcontent.module.css'
import {ContactCard} from 'components/people/contactCard'
import RelatedPosts from 'components/news/relatedPosts'
import {ContentCardWithMeta} from 'components/cards/contentCardWithMeta'
import {withSlider} from 'components/slider'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import Section from 'components/section'
import PublicationCard from 'components/cards'

const NewsSlide = React.forwardRef((props, ref) => {
  let image = null;
  if(props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp && props.featured_media.localFile.childImageSharp.fluid){
    image = props.featured_media.localFile.childImageSharp.fluid
  }
  return(
    <div  style={{width:370}} className="px-3">
    <ContentCardWithMeta ref={ref} {...props} image={image}/>
  </div>)
})

const NewsSlider = (props) => {
  const Slider = withSlider(NewsSlide, props.items);
  return <Slider/>
}

export const Content = (props) => (
  <>
    <header className="entry-header mb-10">
      <Header {...props}/>
    </header>
  <ContentWithShare
    twitter={true}
    facebook={true}
    linkedin={true}>
    <div className="entry-content" dangerouslySetInnerHTML={{__html:props.content}}/>
    {props.resourcesSection && props.resourcesSection.resources.length &&
     <div className="mt-16">

       <Section title={props.resourcesSection.title}>

         <div className="mx-auto container">

           {props.resourcesSection.resources.map(r=>
                                                 <div className="mb-5 flex justify-center" key={r.id}>
                                                   <PublicationCard {...r} />
                                                 </div>
                                                )
           }

         </div>
       </Section>
     </div>



    }
  </ContentWithShare>
    <footer className="entry-footer mt-32 container mx-auto" style={{maxWidth: 1170}}>
      
      { props.contact_section && props.contact_section.person &&
      <div className="mb-16 ">
        <p className={Styles.sectionTitle} dangerouslySetInnerHTML={{__html: props.contact_section.title}} />
        <div className={Styles.contactCardWrapper}>
          <BgPattern className={`${BgStyle.pattern3} ${Styles.contactCardBackground}`}/>
          <ContactCard {...props.contact_section.person}/>
        </div>
      </div>
      }
      {/* props.relatedNews && props.relatedNews.length > 0  && 
        <div className="mb-16 container mx-auto">
          <p className={Styles.sectionTitle} dangerouslySetInnerHTML={{__html: 'Related News'}} />
          
          <NewsSlider items={props.relatedNews}/>
        
        </div>
      */}
    </footer>
  </>
)
