import React from 'react'
import Img from 'gatsby-image'
import Styles from './contactcard.module.css'
import {Link} from 'components/link'
import MediaQuery from 'react-responsive'
import {ReadMoreButton } from 'components/buttons'


const DesktopContactCard = (props) => (
  <div className={Styles.card}>
    <div className={Styles.imageWrapper}>
      <Img fixed={props.featured_media.localFile.childImageSharp.desktop}/>
    </div>
    <div className={Styles.content}>
      <div>
        <p
          className={Styles.title}
          dangerouslySetInnerHTML={{__html:props.title}}/>
        {props.position && 
         <p
           className={Styles.position}
           dangerouslySetInnerHTML={{__html:props.position}}/>
        }
      </div>
      <div>
        <a href={'mailto:' + props.email} className={Styles.email}
              dangerouslySetInnerHTML={{__html:props.email}}
        />
      </div>
      <div>
        <ReadMoreButton

          link={{url:props.path}}
          className={Styles.link}
          text="Open biography"
        />
      </div>
    </div>
  </div>
)
const MobileContactCard = (props)=>(
  <div className={Styles.card}>
    <div className={Styles.imageWrapper}>
      <Img fixed={props.featured_media.localFile.childImageSharp.mobile}/>
    </div>
    <div className={Styles.content}>
      <div>
        <p
          className={Styles.title}
          dangerouslySetInnerHTML={{__html:props.title}}/>
        {props.position && 
         <p
           className={Styles.position}
           dangerouslySetInnerHTML={{__html:props.position}}/>
        }
      </div>
      <div className="my-5">
        <span className={Styles.email}
              dangerouslySetInnerHTML={{__html:props.email}}
        />
      </div>
      <div>
        <ReadMoreButton

          link={{url:props.path}}
          className={Styles.link}
          text="Open biography"
        />
      </div>
    </div>
  </div>
)

export const ContactCard = (props) => {
  return (
    <>
      <MediaQuery query="(min-width: 768px)">
        <DesktopContactCard {...props}/>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <MobileContactCard {...props}/>
      </MediaQuery>
    </>
)
}
