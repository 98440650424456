import React from 'react'
import {BannerWithCard} from 'components/banners'
import Styles from './header.module.css'

export const Header = (props) => {
  return (
    <>
      {props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp && props.featured_media.localFile.childImageSharp.desktop &&

       <BannerWithCard
         image={props.featured_media.localFile.childImageSharp.desktop}
       {...props}
       />
      }
    </>
  )
}
