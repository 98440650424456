import React from "react"
import {graphql} from 'gatsby'
import Layout from "components/layout"
import {Content} from 'components/subjects/content'

const SingleSubjectTemplate = (props) => {
  const data = props.data;
   const {content, date, title, featured_media, tags, slug, classes, ancestors, related_news_section} = data.wordpressWpSubjects
  return(
    <Layout
      articleId={"topics-"+slug}
      ancestors={ancestors}
    >
      <Content {...data.wordpressWpSubjects} relatedNews={data.relatedNews.nodes} />
    </Layout>
  )
}

export default SingleSubjectTemplate

export const SubjectQuery = graphql `
query subjectById($id: String!){
  wordpressWpSubjects(id:{eq:$id}){
    title
    content
    path
    slug
    type
    classes
    date(formatString: "LL")
    wordpress_id
    excerpt
    ancestors {
      label
      link
    }
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
    resourcesSection{
      title
      resources{
          title
          description
          link {
            url
            target
            alt
            title
          }
        }
    }
    contact_section{
      title
      person {
        id
        path
        title
        position
        email
        featured_media {
          localFile{
            childImageSharp{
              desktop: fixed(width:169, height:169) {
                ...GatsbyImageSharpFixed
              }
              mobile: fixed(height:306, width:306) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }

  relatedNews: allWordpressWpNews(filter:{tags:{elemMatch:{type:{eq:"subjects"}}}}){
    nodes{
      wordpress_id
      path
      date(formatString: "LL")
      title
      tags {
        name
      }
      featured_media  {
        localFile {
          childImageSharp {
            fluid ( maxWidth:370){
              ...GatsbyImageSharpFluid

            }
          }
        }
      }
    }
  }
}

`
