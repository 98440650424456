import React from 'react'
import Styles from './stepper.module.css'
import {ReactComponent as Chevron} from 'images/icons/Chevron.svg'
export default (props) => {
  const stepperId=`${props.baseId}Stepper`
  return (
    <div id={`${props.baseId}_stepper`} className={Styles.stepper}>
    {props.hasPrev &&
     <button id={`${stepperId}__prev`} onClick={props.onPrev}>
       <Chevron className={Styles.next}/>
     </button>
    }
    {props.hasNext &&
     <button id={`${stepperId}__next`} onClick={props.onNext} className="ml-auto">
       <Chevron className={Styles.prev}/>
     </button>
    }


  </div>
  )
}
