import React from 'react'
import Style from './slider.module.css'
import Stepper from './stepper'

export const withSlider = (CardComponent, collection, margin) => {
  /**
   * filter must be an object where the key is a property of collection items
   * the schema would be:
   *  filters: {
   *   property_of_item : [{id:someid, name:some name}]
   *  }
   */
  return class extends React.Component {
    constructor(props){
      super(props)
      let _collection = [...collection]
      if(props.maxItems && props.maxItems>-1){
        _collection = _collection.slice(0, maxItems)
      }
      this.margin=margin?margin:1;
      this.state = {
        allItems: [..._collection],
        index:0,
        marginLeft: -margin,
        navigationAvailable: false,
        animationPlaying: true,
        lastItemFullyVisible: false,
        totalWidth: 0
      }
    
      this.frames = 24;
      this.interval = 15;
      this.setRef = (index, element) => this.refArray[index] = element
      this.container = React.createRef()
      this.sliderRef = React.createRef()
      this.refArray = []
    }
    checkIfLast = () => {
      if(this.state.totalWidth < 0){
        return
      }
      const lastItem = this.refArray[this.refArray.length -1 ]
      const containerWidth = this.container.current.getBoundingClientRect().width
      this.setState({lastItemFullyVisible: this.state.totalWidth + this.state.marginLeft - containerWidth<0 })

    }
    onNextHandler =( ) => {
      const elementWidth = this.refArray[this.state.index].getBoundingClientRect().width + 2*this.margin;
      const translation = elementWidth/this.frames;
      let frames = this.frames;
     

      const interval = setInterval(() => {
        if(frames === 0) {
          clearInterval(interval);
          this.setState({animationPlaying: false})
          this.checkIfLast()
          return;
        }
        frames--;
        this.setState({marginLeft: this.state.marginLeft - translation})
      }, this.interval)
    }
    onPrevHandler =( ) => {
      const elementWidth = this.refArray[this.state.index].getBoundingClientRect().width +2*this.margin;
      const translation = elementWidth/this.frames;
      let frames = this.frames;
      const interval = setInterval(() => {
        if(frames == 0) {
          clearInterval(interval);
          this.setState({animationPlaying: false})
          return;
        }
        frames--;
        this.setState({
          marginLeft: Math.min(0,this.state.marginLeft + translation),
          lastItemFullyVisible: false,
        })
      }, this.interval)
      
    }
    componentDidMount() {
      const totalWidth = this.refArray.reduce((value, ref) => {
        return value += ref.clientWidth+2*margin
      }, 0)-margin*2
      if(totalWidth > this.container.current.clientWidth) {
        this.setState({
          navigationAvailable: true,
          totalWidth: totalWidth

        }, ()=>{this.checkIfLast})
      }
      
    }
    render(){
      return(
        <div ref={this.container} className={Style.container}>
          
          <div className={Style.stepper}>
            <Stepper
              hasNext={!this.state.lastItemFullyVisible}
              hasPrev={this.state.marginLeft<-margin}
              onNext={this.onNextHandler}
              onPrev={this.onPrevHandler}
            />
          </div>
          
          <div style={{margin:`0 -${this.margin}px 0 ${this.state.marginLeft}px`}} className={Style.sliderContainer} ref={this.sliderRef} >

            {this.state.allItems.map((item, index) =>(
              <div  className={Style.item} key={item.id} style={{marginLeft:`${this.margin}px`, marginRight: `${this.margin}px`}}>
                <CardComponent
                  ref={this.setRef.bind(this,index)}
                  {...item}/>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }
}
